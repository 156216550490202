import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Block, Delete, Edit } from '@material-ui/icons';
import { breakStringIntoLines, breakStringIntoLinesBySymbol, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { contributorItemsSelector } from '../../redux/contributor';
import {
  BlockAndUnBlockContributor,
  contributorListings,
  deleteContributor,
} from '../../Services/ContributorApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import AddContributor from '../Modals/AddContributor';
import ConfirmationModal from '../Modals/ConfirmationModal';
import BlockIcon from '@mui/icons-material/Block';
import EditContributor from '../Modals/EditContributor';
import MatTable from '../Tables/MatTable';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import EditPasswordAdminUserModal from '../Modals/EditPasswordAdminUserModal';
import LockResetIcon from '@mui/icons-material/LockReset';
import EditPasswordContributorModal from '../Modals/EditPasswordContributorModal';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import DoNotDisturbOffIcon from '@mui/icons-material/DoNotDisturbOff';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import LockIcon from '@mui/icons-material/Lock';
import { SearchByContributor } from '../../data/userData';
import AllFilters from '../Order/AllFilters';


const limit = 10;

const column = [
  {
    label: 'ID',
    id: 'contributor_id',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => {
      if(row?.contributor_id){
        return columnData ? <Link className='textDecoration-none link-text' to={`contributorDetail/${row?.contributor_id}/${row?.email}`} >{columnData}</Link> : 
          <Typography variant='span' style={{ whiteSpace: 'nowrap' }}>{columnData}</Typography>
      }
      return null 
    },
  },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Name',
    id: 'name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Address',
    id: 'address',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData?breakStringIntoLines(columnData,20):'N/A'}
      </span>
    ),
  },
  {
    label: 'City',
    id: 'city',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'State',
    id: 'state',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData?breakStringIntoLines(columnData,10):'N/A'}
      </span>
    ),
  },
  {
    label: 'Country',
    id: 'country',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Telephone',
    id: 'telephone',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Mobile',
    id: 'mobile',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Inden Code',
    id: 'inden_code',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Percentage',
    id: 'percentage',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
];

function ContributorList() {
  const [tableListing, setTableListing] = useState([]);
  const [editPasswordModal, setEditPasswordModal] = useState(false);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { contributorList } = useSelector(contributorItemsSelector);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const navigate = useNavigate()
  const location = useLocation();

  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'Search By',
      id: 'search_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: SearchByContributor,
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          // newState[index].value = e.target.value;
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      order_status: String(1),
      ...allParams,
      ...params,
    };
    dispatch(contributorListings(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params);
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])


  useEffect(() => {
    if (contributorList?.items?.data?.length >= 0) {
    
      setTableListing(contributorList?.items?.data);
    }
  }, [contributorList.items]);

  const handleAdd = (value) => {
    setOpen(value);
  };

  const handleEdit = (value, data = {}) => {
    setEditOpen(value);
    setSelectedData(data);
  };

  const handleConfirmDelete = async (value) => {
    if (value && deleteData) {
      const resp = await deleteContributor({ contributor_id: deleteData });
      if (resp) {
        getListing({ page: '1' });
        setPage(1);
        setDeleteData(null);
      }
    } else {
      setDeleteData(null);
    }
  };


  const handleConfirmBlock= async (value) => {
    if (value && deleteData) {
       let payload={contributor_id:deleteData?.contributor_id,status:deleteData?.status===1?0:1};
    let resp= await BlockAndUnBlockContributor(payload);
      if (resp) {
        getListing({ page: '1' });
        setPage(1);
        setDeleteData(null);
      }
    } else {
      setDeleteData(null);
    }
  };

  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  };

  const handleEditPasswordAdminModal = (value = true) =>{
    setEditPasswordModal(value)
    if(!value){
      setSelectedData(null)

    }
  };

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };

  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[0].value = '';
      newState[1].value = '';
      return newState;
    });
  };

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
         {data?.status==1?<>
          <CustomButton
            toolTipTittle={'Block'}
            onClick={() => {
              // handleEdit(true, data)
              handleCofirmModalOpen(true);
              setDeleteData(data);
              // setBlockUnBlock(data);
            }}
            className="buttonDense noMinWidth"
            sx={{
              bgcolor: 'buttonSuccess.main',
              color: 'buttonSuccess.contrastText',
            }}
          >
            {/* <Block /> */}
            {/* <DoNotDisturbOnIcon/> */}
            <LockPersonIcon/>
            
          </CustomButton>
          </>:<>
          <CustomButton
            toolTipTittle={'UnBlock'}
            onClick={() => {
              // handleEdit(true, data)
              handleCofirmModalOpen(true);
              setDeleteData(data);
              // setBlockUnBlock(data);
            }}
            className="buttonDense noMinWidth"
           
            sx={{
              bgcolor: 'buttonDanger.main',
              color: 'buttonDanger.contrastText',
            }}
          >
            {/* <DoNotDisturbOffIcon/> */}
            <LockIcon/>
          </CustomButton>
          </>}
        </div>
      </>
    );
  };

  return (
    <CustomBox padding={0} sx={{minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center"}}>
      <MatTable
        loading={contributorList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
      
        action={actionBody}
        page={page}
        perPage={limit}
        total={contributorList?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        // toolBarButton={() => {
        //   return (
        //     <>
        //       <CustomButton
        //         onClick={() => {
        //           handleAdd(true);
        //         }}
        //         color={'buttonPrimary'}
        //          sx={(theme)=>({ padding: '10px 10px', marginRight: 1, 
        //         [theme.breakpoints.down("md")]: {
        //           flex: 1,
        //         }
        //       })}
        //       >
        //         Add Contributor
        //       </CustomButton>
        //     </>
        //   );
        // }}
        pagination={true}
        filtersUrlData={allParams || {}}
        // filterEnable
        rangeDateFilter
        disableSearch
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
            extraButton={() => {
              return <>
                <CustomButton onClick={() => { handleAdd(true) }} color={'buttonPrimary'} sx={(theme) => ({
                  padding: '10px 10px', marginRight: 1, marginTop: 1,
                  [theme.breakpoints.down("md")]: {
                    flex: 1,
                  }
                })}>Add Contributor</CustomButton>
              </>
            }}

          />
        )}
      />
      {open && (
        <AddContributor
          open={open}
          handleOpen={handleAdd}
          setPage={setPage}
          getListing={getListing}
        />
      )}
      {editOpen && (
        <EditContributor
          open={editOpen}
          handleOpen={handleEdit}
          setPage={setPage}
          getListing={getListing}
          selectedData={selectedData}
        />
      )}
      {confirmDelete && (
        <ConfirmationModal
          open={confirmDelete}
          handleConfirm={handleConfirmBlock}
          setOpen={handleCofirmModalOpen}
        />
      )}
            {editPasswordModal && <EditPasswordContributorModal open={editPasswordModal} handleOpen={handleEditPasswordAdminModal} getListing={getListing} selectedData={selectedData}/>}

    </CustomBox>
  );
}

export default ContributorList;
