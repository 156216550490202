import React from 'react';
import MainLayout from '../Layouts/MainLayout';
import ShootIDListingContainerPages from './ShootIDListingContainerPages';

function ShootIDListingContainer() {
  return (
    <MainLayout navTitle={"Shoot ID Listing"} navSubTitle={"Welcome to ImagesBazaar"}>
      <ShootIDListingContainerPages />
    </MainLayout>
  );
}

export default ShootIDListingContainer;
