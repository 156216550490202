import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, MenuItem } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import {
  createCommonSettingCategoryTableData,
  formatDate,
  handleFileReader,
} from '../../lib/helper';
import { fileUpload } from '../../Services/commonService';
import { uploadWeeklyImages } from '../../Services/DashboardApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import { updateImage } from '../../Services/imageApis';
import { commonItemsSelector } from '../../redux/common';
import { useSelector } from 'react-redux';

export default function EditImageDetailModelNewShoot({
  open,
  handleOpen,
  data,
  getListing,
}) {
  const [loading, setLoading] = React.useState(false);
  const { appSetting } = useSelector(commonItemsSelector);
  const [categoryTableData, setCategoryTableData] = React.useState([]);

  const handleUpload = async (values, { resetForm }) => {
    try {
      setLoading(true);
      let payload = {
        image_name: data?.name,
        rank: Number(values.rank),
        image_rank: Number(values.image_rank),
        is_star: data?.is_star || '0',
        is_contributor: data?.is_contributor || '0',
        image_type: data?.image_type,
        watermark: data?.watermark,
        pricing: data?.pricing
      };
      let resp = await updateImage(payload);
      if (resp) {
        getListing();
        handleOpen(false);
      }
      setLoading(false);
      resetForm();
    } catch (e) {
      setLoading(false);
      console.log(e, 'error');
    }
  };
  const formik = useFormik({
    initialValues: {
      image_name: data?.Name || '',
      rank: data?.rank1 || '',
      image_rank: data?.rank || '',
      is_start: data?.is_star || '0',
      is_contributor: data?.is_contributor || '0',
      image_type: data?.image_type,
      watermark: data?.watermark,
      sold_count: data?.sold_count || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      image_rank: Yup.string()
        .required('Image rank is required.')
        .matches(/^\d+$/, 'Image rank should be number only.'),
      rank: Yup.string().required('Rank is required.'),
    }),
    onSubmit: handleUpload,
  });

  return (
    <>
      <Dialog maxWidth={'lg'} open={open} onClose={() => {}}>
        <DialogTitle variant="h3">Edit Image Details</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} sm={4} md={3}>
                <CustomImage
                  style={{ width: '100%', borderRadius: 8 }}
                  src={data.image_url}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Grid container spacing={2} mt={0}>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      name="image_rank"
                      label="Image rank"
                      placeholder="Image rank"
                      value={formik.values.image_rank}
                      disabled={true}
                      onChange={(e) => {
                        formik.setFieldValue('image_rank', e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      error={
                        formik.touched.image_rank &&
                        Boolean(formik.errors.image_rank)
                      }
                      helperText={
                        formik.touched.image_rank && formik.errors.image_rank
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      name="rank"
                      label="Rank1"
                      placeholder="Rank"
                      value={formik.values.rank}
                      onChange={(e) => {
                        formik.setFieldValue('rank', e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      error={formik.touched.rank && Boolean(formik.errors.rank)}
                      helperText={formik.touched.rank && formik.errors.rank}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      name="sold_count"
                      label="Sold Count"
                      placeholder="Sold Count"
                      value={formik.values.sold_count}
                      disabled={true}
                      onChange={(e) =>
                        formik.setFieldValue('sold_count', e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      error={
                        formik.touched.sold_count &&
                        Boolean(formik.errors.sold_count)
                      }
                      helperText={
                        formik.touched.sold_count && formik.errors.sold_count
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Save
              </CustomButton>
              <CustomButton
                onClick={() => handleOpen(false)}
                disabled={loading}
              >
                Close
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

EditImageDetailModelNewShoot.defaultProps = {
  open: false,
  handleOpen: () => {},
  data: null,
  getListing: () => {},
  type: 'allimage',
};
