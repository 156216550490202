import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  getObjectSubset,
  getNewParameters,
  getParameters,
  breakStringIntoLines,
} from '../../lib/helper';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MatTable from '../Tables/MatTable';
import { contributorItemsSelector } from '../../redux/contributor';
import { queriesList } from '../../Services/ContributorApis';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import AllFilters from '../Order/AllFilters';
import { QueryStatus } from '../../data/contributorsData';


const column = [
  {
    label: 'Contributor ID',
    id: 'contributor_id',
    format: (columnData) => (
      <Typography variant="span" style={{ whiteSpace: 'nowrap' }}>
        {columnData}
      </Typography>
    ),
  },
  {
    label: 'Name',
    id: 'name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}
      </span>
    ),
  },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Query',
    id: 'queries',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData ? breakStringIntoLines(columnData, 20) : 'N/A' }
      </span>
    ),
  },
  {
    label: 'Status',
    id: 'status',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData.toString() == '1' ? 'Resolved' : 'Pending'}
      </span>
    ),
  },
];

function ContributorsQueries() {
  // const [loading, setLoading] = useState(false);
  // const limitOptions = [10, 50, 100, 250];
  // const [limit, setLimit] = useState(limitOptions[0]);
  const limit = 10;
  const [tableListing, setTableListing] = useState([]);
  const { contributorQueriesList } = useSelector(contributorItemsSelector);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'Query status',
      id: 'status',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: QueryStatus,
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);

  const getOrderListing = async (params = {}) => {
    const selectedStatus =
      filtersArray.find((filter) => filter.id === 'status')?.value || '';
    const payload = {
      page: String(page),
      limit: String(limit),
      search: allParams?.search,
      status: selectedStatus,
      ...allParams,
      ...params,
    };
    dispatch(queriesList(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getOrderListing();
  }, []);

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getOrderListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };

  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[0].value = '';
      newState[1].isVisible = true;
      newState[1].value = '';
      return newState;
    });
  };

  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }

    getOrderListing();
  }, [allParams]);

  useEffect(() => {
    if (contributorQueriesList?.items?.rows?.length >= 0) {
      let data = contributorQueriesList?.items?.rows?.map((item) => {
        return {
          name: item?.contributor?.name,
          email: item?.contributor?.email,
          contributor_id: item?.contributor?.contributor_id,
          queries: item?.queries,
          status: item?.status,
        };
      });
      setTableListing(data);
    }
  }, [contributorQueriesList.items]);

  return (
    <CustomBox padding={0} sx={{ minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <MatTable
        loading={contributorQueriesList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        page={page}
        perPage={limit}
        total={contributorQueriesList?.items?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getOrderListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        pagination={true}
        disableSearch
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
          />
        )}
      />
    </CustomBox>
  );
}

export default ContributorsQueries;
