export const OrderData = {
  tabs: [
    {
      label: 'All',
      id: 1,
    },
    {
      label: 'Pending',
      id: 2,
    },
    {
      label: 'Confirmed',
      id: 3,
    },
    {
      label: 'Rejected',
      id: 4,
    },
    {
      label: 'Proposal Management',
      id: 5,
    },
    {
      label: 'IVS Download',
      id: 6,
    },
    {
      label: 'IVS Order',
      id: 7,
    },

    // {
    //   label: 'Subscription',
    //   id: 8,
    // }
  ],
};


export const orderDetalData = {
  discountType: [
    {
      value: 'Select Discount Type',
      label: 'Select Discount Type',
    },
    {
      value: 'Commision',
      label: 'Commision',
    },
    {
      value: 'Corporate Deal',
      label: 'Corporate Deal',
    },
    {
      value: 'NO Discount',
      label: 'NO Discount',
    },
    {
      value: 'Performa',
      label: 'Performa',
    },
    {
      value: 'Publication Pckg',
      label: 'Publication Pckg',
    },
    {
      value: 'Publicis Group Discount',
      label: 'Publicis Group Discount',
    },
    {
      value: 'UpFront',
      label: 'UpFront',
    },
    {
      value: 'WPP Discount',
      label: 'WPP Discount',
    },
  ],
  userType: [
    {
      value: 'New',
      label: 'New',
    },
    {
      value: 'Existing',
      label: 'Existing',
    }
  ],
  createPeriod: [
    {
      value: '0',
      label: '0',
    },
    {
      value: '7',
      label: '7',
    },
    {
      value: '15',
      label: '15',
    },
    {
      value: '30',
      label: '30',
    },
    {
      value: '45',
      label: '45',
    },
    {
      value: '60',
      label: '60',
    },
    {
      value: '90',
      label: '90',
    },
  ],
  approvalMode: [
    {
      value: 'Cheque copy/deposit slip',
      label: 'Cheque copy/deposit slip',
    },
    {
      value: 'PO',
      label: 'PO',
    },
    {
      value: 'Quotation approval',
      label: 'Quotation approval',
    },
    {
      value: 'Mail approval',
      label: 'Mail approval',
    },
    {
      value: 'PDC',
      label: 'PDC',
    },
    {
      value: 'Upfront',
      label: 'Upfront',
    },
    {
      value: 'NEFT/SWIFT',
      label: 'NEFT/SWIFT',
    },
  ],
  closedBy: [
    {
      value: 'Select',
      label: 'Select Closed By',
    },
    {
      value: 'CS',
      label: 'CS',
    },
    {
      value: 'Sales',
      label: 'Sales',
    },
    {
      value: 'Exclude',
      label: 'Exclude',
    },
    {
      value: 'Special package',
      label: 'Special package',
    },
    {
      value: 'Renew',
      label: 'Renew',
    },
    {
      value: 'Sales-Renew',
      label: 'Sales-Renew',
    },
    {
      value: 'Legal',
      label: 'Legal',
    },
  ],
  orderStatus: [
    {
      value: '0',
      label: 'Pending',
    },
    {
      value: '1',
      label: 'Confirmed',
    },
    {
      value: '2',
      label: 'Rejected',
    },
  ],
  paymentStatus: [
    {
      value: '0',
      label: 'Unpaid',
    },
    {
      value: '1',
      label: 'Paid',
    }
  ],
};

export const Select_Sez = [
  {
    label: 'Select SEZ',
    value: '0'
  },
  {
    label: 'SEZ with payment',
    value: '1',
  },
  {
    label: 'SEZ without payment',
    value: '2',
  },
 
];



