import { Typography } from '@mui/material'
import React from 'react'
import CustomBox from '../CommonComp/CustomBox/CustomBox'
import ContributorList from './ContributorList';
import ContributorsDailySalesReport from './ContributorsDailySalesReport';
import ContributorSoldImageList from './ContributorSoldImageList';
import ContributorUploadList from './ContributorUploadList';
import ContributorsQueries from './ContributorsQueries'

function ContributorTabBody({ activeTab }) {
  return (
    <>
      {activeTab == 1 && <ContributorList />}
      {activeTab == 2 && <ContributorSoldImageList />}
      {activeTab == 3 && <ContributorsDailySalesReport />}
      {activeTab == 4 && <ContributorUploadList />}
      {activeTab == 5 && <ContributorsQueries />}
    </>
  )
}

export default ContributorTabBody