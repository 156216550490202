import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import Divder from '../CommonComp/Divder';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { useDispatch, useSelector } from 'react-redux';
import { cmsItemsSelector } from '../../redux/cms';
import { editAddCMS, getCMSInfo } from '../../Services/cmsService';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import AddContactModal from '../Modals/AddContactModal';

function Contacts() {
  const [loading, setLoading] = useState(false);
  const { cmsData } = useSelector(cmsItemsSelector);
  const dispatch = useDispatch();
  const [countryShortName, setCountryShortName] = useState('in');
  const [addContacts, setAddContacts] = useState(false);
  const [editContacts, setEditContacts] = useState(false);
  const [editInfo, setEditInfo] = useState(null);
  const [editDeleteIndex, setEditDeleteIndex] = useState(-1);
  let cmsDataHome = cmsData ? cmsData?.items?.length > 0 && JSON?.parse(cmsData?.items[0]?.key_value) : [];

  const handleConfirm = async (values, { resetForm }) => {
    let newObj = { ...cmsDataHome, ContactSection: values };
    setLoading(true);
    let str1 = JSON.stringify(newObj);
    let obj = { pagename: 'homePage', pagedata: str1 }
    let resp = await editAddCMS(obj);
    if (resp) {
      getListing();
    }
    setLoading(false);
  };

  useEffect(() => {
    getListing();
  }, [])

  const getListing = () => {
    let payload = { pagename: 'homePage' }
    dispatch(getCMSInfo(payload));
  }


  const handleChange = (content) => {
    if (content == '<p><br></p>') {
      formik.setFieldValue('content', '')
    } else {
      formik.setFieldValue('content', content)
    }
  }

  const formik = useFormik({
    initialValues: {
      aboutUs: cmsDataHome?.AboutUsSection?.aboutUs || '',
      heading1: cmsDataHome?.AboutUsSection?.heading1 || '',
      content1: cmsDataHome?.AboutUsSection?.content1 || '',
      heading2: cmsDataHome?.AboutUsSection?.heading2 || '',
      content2: cmsDataHome?.AboutUsSection?.content2 || '',
      heading3: cmsDataHome?.AboutUsSection?.heading3 || '',
      content3: cmsDataHome?.AboutUsSection?.content3 || '',
      content4: cmsDataHome?.AboutUsSection?.content4 || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      aboutUs: Yup?.string().required("About US is Required!"),
      heading1: Yup?.string().required("Heading is Required!"),
      content1: Yup?.string().required("Content is Required!"),
      heading2: Yup?.string().required("Heading is Required!"),
      content2: Yup?.string().required("Content is Required!"),
      heading3: Yup?.string().required("Heading is Required!"),
      content3: Yup?.string().required("Content is Required!"),
      content4: Yup?.string().required("Content is Required!"),
    }),
    onSubmit: handleConfirm,
  });

  const handleAdd = (value = false) => {
    setAddContacts(value);
    setEditContacts(value);
  }

  const editData = (data, index) => {
    setEditInfo(data);
    setEditDeleteIndex(index);
    setEditContacts(true);
    //editDeleteIndex(-1);
  }

  const deleteData = async (data, index) => {
    let arr = [...cmsDataHome?.contactsSection];
    let newArr = arr?.filter((a, index1) => index1 != index);
    let cmsObj = { ...cmsDataHome, contactsSection: newArr };
    let str1 = JSON.stringify(cmsObj);
    let obj = { pagename: 'homePage', pagedata: str1 }
    let resp = await editAddCMS(obj);
    if (resp) {
      getListing();
    }
  }
  return (
    <>
      <CustomBox padding={1} >
        <Box
          noValidate
          component="form"
          onSubmit={formik.handleSubmit}
          marginTop={2}
          marginLeft={2}
        >
          <Grid className='container'>
            <div style={{ textAlign: 'end' }} >
              <CustomButton onClick={() => setAddContacts(true)} sx={{ bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}>Add New</CustomButton>
            </div>
          </Grid>

          <Typography variant='h5' mb={2} >Contact list</Typography>
          {cmsDataHome?.contactsSection && cmsDataHome?.contactsSection?.length > 0 ? <>
            {cmsDataHome?.contactsSection?.map((a, index) => {
              return <Grid container spacing={2} my={2}>
                <Grid item xs={3} md={3} lg={3}>
                  {a?.city}
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  {a?.contacts?.map((contact) => {
                    return contact
                  })?.join(",")}
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                  <Edit onClick={() => editData(a, index)} />
                  <Delete onClick={() => deleteData(a, index)} />

                </Grid>
              </Grid>
            })}
          </> : <>
            No Contacts Added
          </>}
        </Box>
      </CustomBox>
      {addContacts && <AddContactModal open={addContacts} type={'add'} handleOpen={handleAdd} cmsDataHome={cmsDataHome} handleLast={getListing} />}
      {editContacts && <AddContactModal open={editContacts} type={'edit'} handleOpen={handleAdd} cmsDataHome={cmsDataHome} handleLast={getListing} data={editInfo} editIndex={editDeleteIndex} />}
    </>
  );
}

export default Contacts;